import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { currentLocale, defaultLocale, supportedLocales } from 'constants/locale'
import Env from 'helpers/environment'

const locale = supportedLocales.includes(currentLocale) === true ? currentLocale : defaultLocale

const options = {
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
  backend: {
    loadPath: `${Env.PUBLIC_CDN_URL}/partners-translations/${locale}.json`,
  },
}

i18next.use(HttpBackend).use(initReactI18next).init(options)

export default i18next
