import { useTranslation } from 'react-i18next'
import { MdOutlineFlag } from 'react-icons/md'
import { useGetReportAnIssueUrl } from 'helpers/typeform'
import Button from 'components/common/Button'
import Card from 'components/common/Card'

type IssueReportFormType = {
  className?: string
  collectId?: string
  storeName?: string
  businessName?: string
  storeId?: number
  businessId?: number
  referrer: string
  headingText: string
  descriptionText: string
}

const IssueReportForm = ({
  className,
  collectId,
  storeName,
  businessName,
  storeId,
  businessId,
  referrer,
  headingText,
  descriptionText,
}: IssueReportFormType) => {
  const { t } = useTranslation()
  const typeformUrl = useGetReportAnIssueUrl({
    collectId,
    storeName,
    businessName,
    storeId,
    businessId,
    referrer,
  })

  return (
    <Card className={className}>
      <Card.Heading>{headingText}</Card.Heading>
      <Card.TextSection>{descriptionText}</Card.TextSection>
      <Card.Action>
        <Button
          leftContent={<MdOutlineFlag />}
          href={typeformUrl}
          target="_blank"
        >
          {t('collect.issue-report-form.button-text')}
        </Button>
      </Card.Action>
    </Card>
  )
}

export default IssueReportForm
