import Env from 'helpers/environment'

export const DASHBOARD = 'dashboard'
export const IMPACT_DATA = 'impact-data'
export const COLLECT = 'collect'
export const STORE_POSTER = 'poster'
export const COLLECTIONS = 'collections'
export const API_KEYS = 'api-keys'

export const COLLECT_STORE_PAGE_URL = '/:collectId'
export const COLLECT_LANDING_PAGE_URL = '/'
export const DASHBOARD_URL = '/dashboard'
export const IMPACT_DATA_URL = '/impact-data'
export const MAGIC_LOGIN_URL = '/login'
export const EMAIL_REQUESTED_URL = '/email-requested'
export const STORE_POSTER_URL = '/collect-poster'
export const COLLECTIONS_URL = '/collections'
export const API_KEYS_URL = '/api-keys'

export const getSidebarLinks = (t) => {
  return {
    [DASHBOARD]: {
      url: DASHBOARD_URL,
      text: t('layouts.sidebar.links.dashboard'),
    },
    [COLLECTIONS]: {
      url: COLLECTIONS_URL,
      text: t('layouts.sidebar.links.collections'),
    },
    [IMPACT_DATA]: {
      url: IMPACT_DATA_URL,
      text: t('layouts.sidebar.links.impact-data'),
    },
    [COLLECT]: {
      url: Env.PUBLIC_COLLECT_BASE_URL,
      text: t('layouts.sidebar.links.collect'),
    },
    [STORE_POSTER]: {
      url: STORE_POSTER_URL,
      text: t('layouts.sidebar.links.store_poster'),
    },
    [API_KEYS]: {
      url: API_KEYS_URL,
      text: t('layouts.sidebar.links.api-keys'),
    }
  }
}
