type SpacerType = {
  width?: string
  height?: string
}

const Spacer = ({ width = '0', height = '0' }: SpacerType) => {
  return <div style={{ height, width }} />
}

export default Spacer
