import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Debug from 'helpers/debug'

const debug = Debug('hooks:api:usePosterData')

const fetchPosterDataRequest = async ({ queryKey }) => {
  debug(queryKey)
  const [_key, { businessId }] = queryKey
  const { data } = await axios.get(`/api/v1/businesses/${businessId}/dictionary`)
  return data.entries
}

const usePosterData = ({ businessId }) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ['posterData', { businessId }],
    queryFn: fetchPosterDataRequest,
    enabled: Boolean(businessId),
  })

  return {
    data,
    isLoading: isPending,
    isFetching,
  }
}

export default usePosterData
