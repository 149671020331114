import { HTMLAttributeAnchorTarget } from 'react'
import clsx from 'clsx'
import styles from './Button.module.scss'

type ButtonProps = {
  children: any
  className?: string
  disabled?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  onClick?: (event?: any) => void | (() => void) | Promise<void>
  variant?: 'secondary' | 'no-button'
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disabled = false,
  href,
  target,
  leftContent,
  rightContent,
  onClick,
  variant,
  type = 'button',
  ...otherProps
}) => {
  if (href) {
    return (
      <a
        href={href}
        role="button"
        className={clsx(className, styles.button, {
          [styles.secondary]: variant === 'secondary',
          [styles.noButton]: variant === 'no-button',
        })}
        onClick={onClick}
        target={target}
        {...otherProps}
      >
        {leftContent && <span className={styles.leftContent}>{leftContent}</span>}
        {children}
        {rightContent && <span className={styles.rightContent}>{rightContent}</span>}
      </a>
    )
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(className, styles.button, {
        [styles.secondary]: variant === 'secondary',
        [styles.noButton]: variant === 'no-button',
      })}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {leftContent && <span className={styles.leftContent}>{leftContent}</span>}
      {children}
      {rightContent && <span className={styles.rightContent}>{rightContent}</span>}
    </button>
  )
}

export default Button
