import { useTranslation } from 'react-i18next'
import HeaderCollect from 'components/layout/Collect/Navigation'
import HeaderHub from 'components/layout/Hub/Header'
import styles from './ErrorBoundary.module.scss'

type ErrorBoundaryType = {
  layout: 'hub' | 'collect'
}
const ErrorBoundary = ({ layout }: ErrorBoundaryType) => {
  const { t } = useTranslation()

  return (
    <>
      {layout === 'hub' && <HeaderHub />}
      {layout === 'collect' && <HeaderCollect />}
      <div className={styles.wrapper}>
        <h1>{t('components.error-boundary.title')}</h1>
        <p>{t('components.error-boundary.message')}</p>
      </div>
    </>
  )
}

export default ErrorBoundary
