import React from 'react'
import type { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './Card.module.scss'

type CardType = {
  Element?: keyof React.JSX.IntrinsicElements
  className?: string
  children: ReactNode
  style?: React.CSSProperties
}
const Card = ({ Element = 'article', className, children, style }: CardType) => {
  return (
    <Element
      className={clsx(className, styles.card)}
      style={style}
    >
      <div className={styles.content}>{children}</div>
    </Element>
  )
}

type CardHeadingType = {
  className?: string
  children: ReactNode
}
const CardHeading = ({ className, children }: CardHeadingType) => (
  <h3 className={clsx(styles.heading, className)}>{children}</h3>
)

type CardTextSectionType = {
  className?: string
  children: ReactNode
}
const CardTextSection = ({ className, children }: CardTextSectionType) => (
  <div className={clsx(className, styles.textSection)}>{children}</div>
)

type CardActionType = {
  className?: string
  children: ReactNode
}
const CardAction = ({ className, children }: CardActionType) => (
  <div className={clsx(className, styles.action)}>{children}</div>
)

Card.Heading = CardHeading
Card.TextSection = CardTextSection
Card.Action = CardAction

export default Card
