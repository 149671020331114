export const errorMessages = {
  406: 'collect.error.collection_time_passed',
  404: 'collect.error.store_not_found',
}

export const getFieldErrorMessage = ({ t, errorType }) => {
  switch (errorType) {
    case 'required':
      return t('form_validation.required')
    case 'maxLength':
      return t('form_validation.max_length')
    case 'selectedOptionRequired':
      return t('form_validation.selected_option_required')
    default:
      return t('form_validation.generic_error')
  }
}
