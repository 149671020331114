import { ReactNode } from 'react'
import clsx from 'clsx'
import { alertIcons } from 'config/alert'
import styles from './Alert.module.scss'

type AlertType = {
  className?: string
  variant: 'error' | 'info' | 'status'
  children?: ReactNode
}

const Alert = ({ className, variant, children }: AlertType) => {
  const Icon = alertIcons[variant]

  return (
    <div role="alert" className={clsx(styles.alert, styles[variant], className)}>
      {Icon && (
        <Icon
          className={styles.alertIcon}
          data-testid={variant}
        />
      )}
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default Alert
