import { useTranslation } from 'react-i18next'
import { MdDehaze, MdLogout } from 'react-icons/md'
import { useSessionData } from 'hooks'
import Button from 'components/common/Button'
import { useLogout } from 'hooks/api/hub'
import useIsMobile from 'hooks/useIsMobile'
import LogoImage from 'images/olio-logo-header.svg?react'
import styles from './Header.module.scss'

type HeaderType = {
  toggleSidebar?: () => void
}

const Header = ({ toggleSidebar }: HeaderType) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { isUserAuthenticated } = useSessionData()
  const logout = useLogout()

  const showHamburger = isMobile === true && isUserAuthenticated === true
  return (
    <nav>
      <div className={styles.navWrapper}>
        {showHamburger === true && (
          <button
            type="button"
            className={styles.navButton}
            aria-label={t('layouts.authenticated.sidebar_toggle')}
            tabIndex={0}
            onClick={toggleSidebar}
          >
            <i aria-hidden="true">
              <MdDehaze />
            </i>
          </button>
        )}
        <div className={styles.logoContainer}>
          <a
            href={t('layouts.join-default.header-link')}
            className={styles.brandLogo}
          >
            <LogoImage />
            {isMobile === false && <h1>{t('phrases.partner-hub')}</h1>}
          </a>
        </div>
        {isMobile === false && (
          <ul className={styles.links}>
            <li>
              <a
                className={styles.headerLink}
                href={t('external-urls.olio-website')}
              >
                {t('phrases.olio-website')}
              </a>
            </li>
            {isUserAuthenticated && (
              <li>
                <Button
                  onClick={logout}
                  variant="no-button"
                  className={styles.headerLink}
                >
                  <MdLogout className={styles.icon} />
                  {t('phrases.logout')}
                </Button>
              </li>
            )}
          </ul>
        )}
      </div>
    </nav>
  )
}

export default Header
