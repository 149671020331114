import mixpanel from 'mixpanel-browser'
import Env, { isProductionLike } from 'helpers/environment'
import Debug from 'helpers/debug'

const debug = Debug('analytics:mixpanel')
class Mixpanel {
  mixpanel: typeof mixpanel

  constructor() {
    if (this.mixpanel !== undefined || Env.PUBLIC_MIXPANEL_API_KEY === undefined) {
      return
    }

    this.mixpanel = mixpanel
    this.mixpanel.init(Env.PUBLIC_MIXPANEL_API_KEY, {
      ignore_dnt: true,
      batch_requests: true,
      debug: isProductionLike === false,
    })
  }

  identify = (id) => {
    if (!this.mixpanel) {
      debug('Mixpanel is not initialized')
      return
    }
    this.mixpanel.identify(id)
  }

  setAffiliations = (affiliations) => {
    if (!this.mixpanel) {
      return
    }
    this.mixpanel.people.set(affiliations)
  }

  setContactId = (contactId) => {
    if (!this.mixpanel) {
      return
    }
    this.mixpanel.people.set({
      contact_id: contactId,
    })
  }

  reset = () => {
    this.mixpanel.reset()
  }

  track = (eventName, eventPayload = {}) => {
    if (!this.mixpanel) {
      return
    }
    const defaultProperties = {
      service_name: Env.SERVICE_NAME,
    }

    return new Promise<void>((resolve) => {
      mixpanel.track(eventName, Object.assign(eventPayload, defaultProperties), () => {
        resolve()
      })
    })
  }
}

export default new Mixpanel()
