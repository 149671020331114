import styles from './SceneHeader.module.scss'

const SceneHeader = ({ title, description }: { title: string; description?: string }) => {
  return (
    <>
      <h1 className={styles.sceneTitle} data-testid="dashboard-title">{title}</h1>
      {description && <p className={styles.description}>{description}</p>}
    </>
  )
}

export default SceneHeader
