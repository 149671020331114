import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import Mixpanel from 'services/analytics/Mixpanel'

const useLogout = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const logout = useMutation({
    mutationFn: () => axios.delete('api/v1/sessions'),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ['session'] })
      Mixpanel.reset()
      navigate('/')
    },
  })
  return logout.mutate
}

export default useLogout
