import { useQueryClient } from '@tanstack/react-query'

const useSessionData = () => {
  const queryClient = useQueryClient()

  const session = queryClient.getQueryData<SessionDataType>(['session']) ?? null
  const isUserAuthenticated = !!session?.id
  const isInternalUser = /@olioex\.com$|@olioapp\.com$/.test(session?.email || '')
  return { session, isUserAuthenticated, isInternalUser }
}

export default useSessionData
