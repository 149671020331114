import { useMutation, useQueryClient } from '@tanstack/react-query'
import Debug from 'helpers/debug'

const debug = Debug('hooks:useSelectedBusiness')

const useSelectedBusiness = () => {
  const queryClient = useQueryClient()

  const session = queryClient.getQueryData<SessionDataType>(['session']) ?? null

  const selectedBusinessId: number = queryClient.getQueryData(['selectedBusinessId']) ?? 0

  const selectedBusinessStoreIds: number[] =
    queryClient.getQueryData(['selectedBusinessStoreIds']) ?? []

  const updateSelectedBusiness = async (newBusinessId: number) => {
    queryClient.setQueryData(['selectedBusinessId'], newBusinessId)

    const newBusiness = session?.businesses.find((business) => business.id === newBusinessId)
    queryClient.setQueryData(['selectedBusinessStoreIds'], newBusiness.store_ids)
  }

  const { mutate: setSelectedBusinessId } = useMutation({
    mutationFn: updateSelectedBusiness,
    onSuccess: (_, newBusinessId) => {
      debug('Selected business updated successfully', newBusinessId)
      queryClient.invalidateQueries({ queryKey: ['selectedBusinessId'] })
      queryClient.invalidateQueries({ queryKey: ['selectedBusinessStoreIds'] })
    },
  })

  return { selectedBusinessId, selectedBusinessStoreIds, setSelectedBusinessId }
}

export default useSelectedBusiness
