import { createContext, useContext } from 'react'

const RUMComponentContext = createContext({
  componentBreadCrumbs: 'root',
  component: 'root',
})

/**
 * Utility to track errors in RUM with the component chain/breadcrumbs
 * from <RumComponentContextProvider> automatically added
 */
const useDatadogError = () => {
  const componentContext = useContext(RUMComponentContext)
  const RUMGlobal = getGlobalObject().DD_RUM

  if (RUMGlobal === undefined || RUMGlobal === null) {
    return () => {}
  }

  return (error, customAttributes, source) => {
    RUMGlobal.addError(
      error,
      {
        ...componentContext.customAttributes,
        ...customAttributes,
        react: {
          breadcrumbs: componentContext.componentBreadCrumbs,
          component: componentContext.component,
          ...customAttributes?.react,
        },
      },
      source,
    )
  }
}

function getGlobalObject() {
  if (typeof globalThis === 'object') {
    return globalThis
  }

  Object.defineProperty(Object.prototype, '_dd_temp_', {
    get() {
      return this
    },
    configurable: true,
  })
  // eslint-disable-next-line no-undef
  let globalObject = _dd_temp_
  delete Object.prototype._dd_temp_
  if (typeof globalObject !== 'object') {
    if (typeof self === 'object') {
      globalObject = self
    } else if (typeof window === 'object') {
      globalObject = window
    } else {
      globalObject = {}
    }
  }
  return globalObject
}

export default useDatadogError
