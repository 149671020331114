import { useTranslation } from 'react-i18next'
import LogoImage from 'images/olio-logo-header.svg?react'
import styles from './Navigation.module.scss'

const Navigation = () => {
  const { t } = useTranslation()

  return (
    <nav className={styles.navWrapper}>
      <span className={styles.brandLogo}>
        <a href={t('layouts.join-default.header-link')}>
          <LogoImage />
        </a>
        <span className={styles.collectLabel}>{t('collect.title')}</span>
      </span>
      <ul className={styles.website}>
        <li>
          <a
            className={styles.websiteLink}
            href={t('external-urls.olio-website')}
          >
            {t('phrases.olio-website')}
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
