import type { ReactNode } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import clsx from 'clsx'
import styles from './Pill.module.scss'

type PillType = {
  className?: string
  color?: 'grey' | 'purple' | 'green'
  size?: 'small' | 'large'
  showBorder?: boolean
  children: ReactNode
}

type PillIconAndTextType = {
  children: ReactNode
  className?: string
}

const Pill = ({
  children,
  className,
  showBorder = false,
  color = 'purple',
  size = 'large',
  ...otherProps
}: PillType) => {
  return (
    <div
      className={clsx(styles[color], styles[size], styles.pill, className, {
        [styles.showBorder]: showBorder,
      })}
      {...otherProps}
    >
      {children}
    </div>
  )
}

Pill.Icon = function PillIcon({ children, className }: PillIconAndTextType) {
  return <div className={clsx(styles.pillIcon, className)}>{children}</div>
}

Pill.Text = function PillText({ children, className, ...otherProps }: PillIconAndTextType) {
  return (
    <div
      className={clsx(styles.pillText, className)}
      {...otherProps}
    >
      {children}
    </div>
  )
}

Pill.CloseIcon = function PillCloseIcon({ onClick, ...otherProps }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.closeIcon}
      {...otherProps}
    >
      <MdOutlineCancel />
    </button>
  )
}

export default Pill
