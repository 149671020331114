import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { getFieldErrorMessage } from 'helpers/errorMessages'
import styles from './FieldError.module.scss'

type FieldErrorType = {
  error?: {
    message: ReactNode
    type: string
  }
  id?: string
}

const FieldError = ({ error, id }: FieldErrorType) => {
  const { t } = useTranslation()
  const getErrorMessage = () => {
    if (error.message) {
      return error.message
    }
    return getFieldErrorMessage({ t, errorType: error.type })
  }

  return (
    <p
      className={styles.errorText}
      aria-live="polite"
      id={id}
      data-testid="field-error-text"
    >
      {getErrorMessage()}
    </p>
  )
}

export default FieldError
