import { MdOutlineInfo, MdOutlineWarningAmber } from 'react-icons/md'

export const ERROR = 'error'
export const INFO = 'info'
export const STATUS = 'status'

export const alertVariants = [ERROR, INFO, STATUS]

export const alertIcons = {
  [ERROR]: MdOutlineWarningAmber,
  [INFO]: MdOutlineInfo,
  [STATUS]: MdOutlineInfo,
}
