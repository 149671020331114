import styles from './LoadingSpinner.module.scss'

type LoadingSpinnerProps = {
  containerHeight?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ containerHeight = '100%' }) => (
  <div
    className={styles.spinnerContainer}
    style={{ height: containerHeight }}
    data-testid="loading-spinner"
  >
    <span className={styles.spinner} />
  </div>
)

export default LoadingSpinner
