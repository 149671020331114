const TYPEFORM_URL = 'https://olio1.typeform.com/to/Ke3uOcMN'

type ReportAnIssueUrlType = {
  collectId?: string
  storeName?: string
  businessName?: string
  storeId?: number
  businessId?: number
  referrer: string
}
export const useGetReportAnIssueUrl = ({
  collectId,
  storeName,
  businessName,
  storeId,
  businessId,
  referrer,
}: ReportAnIssueUrlType): string => {
  const typeformUrl = new URL(TYPEFORM_URL)

  if (collectId) {
    typeformUrl.searchParams.append('collect_id', collectId)
  }

  if (storeName) {
    typeformUrl.searchParams.append('store_name', storeName)
  }

  if (businessName) {
    typeformUrl.searchParams.append('business_name', businessName)
  }

  if (storeId) {
    typeformUrl.searchParams.append('store_id', `${storeId}`)
  }

  if (businessId) {
    typeformUrl.searchParams.append('business_id', `${businessId}`)
  }

  if (referrer) {
    typeformUrl.searchParams.append('referrer', referrer)
  }

  return typeformUrl.toString()
}
